import { db } from "@/firebase";

const state = {
  condoPhotos: {},
};

const getters = {
  condoPhotos: (state) => state.condoPhotos,
};

const actions = {
  fetchCondoPhotos({ commit }, condoId) {
    db.ref("condo_photos")
      .child(condoId)
      .on("value", (snapshot) => {
        var docs = [];
        snapshot.forEach((doc) => {
          docs.push(doc.val());
        });
        // docs.sort(function(a, b) {
        //   return b.timestamp - a.timestamp;
        // });

        for (var i = docs.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = docs[i];
          docs[i] = docs[j];
          docs[j] = temp;
        }
        // console.log(docs);
        commit("fetchCondoPhotos", docs);
      });
  },
};

const mutations = {
  fetchCondoPhotos: (state, condoPhotos) => {
    state.condoPhotos = condoPhotos;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
