<template>
  <div id="app">
    <!-- <fullscreen :fullscreen.sync="fullscreen" background="#f7f7f7ff"> -->
    <Navbar />
    <router-view />
    <!-- </fullscreen> -->
  </div>
</template>

<script>
import firebase from "firebase/app";
import Navbar from "./components/layouts/Navbar";

export default {
  name: "App",
  // metaInfo: {
  //   // if no subcomponents specify a metaInfo.title, this title will be used
  //   title: "Home",
  //   // all titles will be injected into this template
  //   titleTemplate: "%s | CondoRail",
  // },
  components: {
    Navbar,
  },
  data() {
    return {
      show: false,
    };
  },
  beforeCreate() {
    firebase
      .auth()
      .onAuthStateChanged((user) =>
        this.$store.commit("setLoginStatus", user !== null)
      );
  },
  watch: {
    // fullscreen: function() {
    //   this.show = this.fullscreen;
    // },
  },
  computed: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;300&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  background: #f1f1f2;
  overflow-x: hidden;
}

.th {
  font-family: "Kanit", sans-serif;
}

.page-style {
  height: 100vh;
  background: #fff;
}

.has-shadow {
  box-shadow: 0 0.5em 1em -0.125em rgba(174, 174, 192, 0.267);
}

.px-6 {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}

.is-size-6-7 {
  font-size: 0.8rem !important;
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-content: flex-start;
  margin: auto;
  scrollbar-width: thin;
}

.image.is-38x38 {
  height: 38px;
  width: 38px;
}

.map-shadow {
  box-shadow: 0px 5px 15px rgba(102, 114, 150, 0.3) !important;
}

.ytp-title {
  font-size: 10px !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(189, 195, 211);

  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(165, 170, 185);
}
.columns:last-child {
  margin-bottom: 0rem !important;
}

.centered {
  display: flex !important;
  align-items: center !important;
}

.display-none {
  display: inline !important;
}

.el-dropdown {
  position: static !important;
}

// .el-dropdown-menu {
//   z-index: 3 !important;
// }

.el-dropdown-item:before {
  /* background: #f4f4f5; */
  /* box-shadow: 7px 7px 15px rgba(102, 114, 150, 0.15); */

  overflow-y: visible;
}

.dropdown-stations {
  /* background: #f4f4f5; */
  /* box-shadow: 7px 7px 15px rgba(102, 114, 150, 0.15); */
  background: #f6f6f6 !important;
  height: 25vh !important;
  width: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdown-stations-full {
  /* background: #f4f4f5; */
  /* box-shadow: 7px 7px 15px rgba(102, 114, 150, 0.15); */

  background: #f6f6f6 !important;
  height: 27vh !important;
  width: 97%;
  overflow-y: scroll;
  overflow-x: hidden;
}

a.dropdown-item.is-active {
  background: none;
  color: #00ac6b;
  /* font-weight: 500; */
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
}

.ellipsis-hidden {
  text-overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis-dropdown {
  width: 5rem !important;
  min-width: 5rem !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* cursors */
.cursor-text {
  cursor: text;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-auto {
  cursor: auto;
}

.media-content {
  width: 80%;
}

img.cover {
  object-fit: cover;
}

.condo-image {
  object-fit: cover;
}

.curve-edge,
img {
  border-radius: 0.7rem;
}

.fullscreen-button {
  border: none !important;
  color: #34495c !important;
  border-radius: 0.7rem !important;
  background: #f3f3f5 !important;
}
.fullscreen-button:hover {
  background: #f3f3f5 !important;
}

.arrow-button {
  border: none;
  background: transparent;
  border-radius: 0 0 0.7rem 0.7rem !important;
  transition: 500ms ease-in-out;
}

.button.is-light {
  color: #333333;
  background: #f6f6f6;
  border: none;

  border-radius: 0.5rem;
}

.el-button.neumorphism {
  border-color: rgba(147, 152, 167, 0.1) !important;
  background-color: #f4f4f5;
  border-radius: 0.5rem !important;

  /* box-shadow: 16px 16px 21px #c6cacc, -16px -16px 21px #ffffff; */
  box-shadow: inset 0 0 15px rgba(55, 84, 170, 0),
    inset 0 0 20px rgba(255, 255, 255, 0),
    0px 5px 15px rgba(102, 114, 150, 0.15),
    -5px -5px 15px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, 0.15);
  transition: box-shadow 300ms ease-in-out !important;
}

.el-button.neumorphism:hover {
  box-shadow: inset 7px 7px 15px rgba(102, 114, 150, 0.15),
    inset -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, 0.2);
  transition: box-shadow 300ms ease-in-out;
}

.el-button-group > .el-button:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.el-button-group > .el-button:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.el-button--info.is-plain:hover,
.el-button--info.is-plain:focus {
  color: rgb(132, 169, 206) !important;
  background-color: #e4eef7 !important;
}

// .el-button:active {
//   // border: none;
//   // border: 1px solid #ddd !important;
// }

.el-button:active,
.el-button:focus {
  color: #606266 !important;
  // border: #e0e3e9 !important;
  // background-color: #f6f6f6 !important;
}

.line-button {
  cursor: pointer;
  width: 100%;
  padding-top: 75% !important; /* 4:3 Aspect Ratio */
  position: relative !important;
  display: flex !important;
  align-items: center !important;
}

.line-button .image {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.card {
  box-shadow: 7px 7px 15px rgba(102, 114, 150, 0.15);
}

.condo-card,
.line-button,
.el {
  border: none !important;
  outline: none !important;
  border-radius: 0.7rem;
  background: #f4f4f5;
  /* box-shadow: 16px 16px 21px #c6cacc, -16px -16px 21px #ffffff; */
  box-shadow: inset 0 0 15px rgba(55, 84, 170, 0),
    inset 0 0 20px rgba(255, 255, 255, 0),
    7px 7px 15px rgba(102, 114, 150, 0.15),
    -7px -7px 18px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, 0.2);
  transition: box-shadow 300ms ease-in-out !important;
}

.condo-card:hover {
  box-shadow: inset 0 0 15px rgba(70, 98, 182, 0),
    inset 0 0 20px rgba(255, 255, 255, 0),
    10px 10px 16px rgba(60, 91, 184, 0.15),
    -10px -10px 16px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, 0.3);
  transition: box-shadow 300ms ease-in-out;
  cursor: pointer;
}

.condo-card.active,
.line-button.active {
  /* box-shadow: 16px 16px 21px #c6cacc, -16px -16px 21px #ffffff; */
  box-shadow: inset 7px 7px 15px rgba(102, 114, 150, 0.15),
    inset -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, 0.2);
  transition: box-shadow 300ms ease-in-out;
}

.condo-card-footer {
  border-top: 1px solid #d8dee4;
}
.condo-card-footer-item:not(:last-child) {
  border-right: 1px solid #d8dee4;
}

.profile-loader-24 {
  background-image: radial-gradient(circle 12px, #d3d3d3 100%, transparent 0);
}

.profile-loader-32 {
  background-image: radial-gradient(circle 16px, #d3d3d3 100%, transparent 0);
}

.profile-loader-38 {
  background-image: radial-gradient(circle 19px, #d3d3d3 100%, transparent 0);
}

.profile-loader-48 {
  background-image: radial-gradient(circle 24px, #d3d3d3 100%, transparent 0);
}

.profile-loader-64 {
  background-image: radial-gradient(circle 32px, #d3d3d3 100%, transparent 0);
}

.gm-style .gm-style-iw-c {
  box-shadow: 7px 7px 15px rgba(102, 114, 150, 0.2) !important;
}

/* Element UI */
.el-aside {
  border-radius: 0.75rem;
}

.el-image {
  display: block;
  position: relative;
}
.el-scrollbar__bar {
  opacity: 1 !important;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.1em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation: typing 2s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.sign-in {
  font-size: 1em !important;
  padding: 1rem 1.5rem;
  border-radius: 0.7rem !important;
  box-shadow: 5px 5px 20px rgba(83, 98, 145, 0.3);
  background-color: #4285f4 !important;
  color: white !important;
  transition: 200ms ease-in-out;
  display: flex;
  width: 100%;
}

.sign-in:hover {
  box-shadow: 5px 5px 30px rgba(83, 98, 145, 0.4);
  background-color: #2c78f3 !important;
  color: white !important;
  transition: 300ms ease-in-out;
}

.el-dialog {
  border-radius: 0.7rem !important;
  .el-dialog__header {
    border-bottom: 1px solid #e4e7eb;
    .el-dialog__title {
      font-weight: 600;
    }
  }
  .el-dialog__body {
    padding: 30px 20px 5px 20px !important;
  }
}

.card-header {
  box-shadow: none !important;
}

.scrolling-wrapper-review {
  padding-bottom: 20px;
  white-space: nowrap;
  overflow-x: scroll;

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  // review-card
  // .card:first-child {
  // margin-left: 1rem;
  // }
  // .card:last-child {
  // margin-right: 0.5rem;
  // }

  .card {
    cursor: pointer;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-right: 0.5rem;
    box-shadow: none;
    width: 160px;
    height: 220px;

    transition: all 600ms ease;
    position: relative;
    white-space: initial;
  }
  .card-header,
  .card-link {
    position: relative;
    z-index: 2;
    color: white;
    font-weight: 500;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: rgb(65, 65, 65);
  }

  .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: 600ms;
    background: -webkit-linear-gradient(#252f3d, #252f3d00);
    background: -moz-linear-gradient(#252f3d, #252f3d00);
    background: -o-linear-gradient(#252f3d, #252f3d00);
    background: linear-gradient(#252f3d, #252f3d00);
  }

  .card:hover {
    box-shadow: 5px 5px 15px rgba(160, 169, 177, 1);
  }
  .card:hover .card-link {
    color: #5bff89;
    box-shadow: 5px 5px 25px rgba(160, 169, 177, 1);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .card:hover .overlay {
    opacity: 1;
  }

  // review
  .review-button {
    cursor: pointer;
    float: left;
    margin-right: 0.8rem;
    box-shadow: none;
    width: 95px;
    height: 173px;
    // background: #34495c;
    background: #f3f3f5;
    // background: #f1f2f6;

    border-radius: 0.7rem;
    font-size: 0.8rem;

    padding-top: 65px;
    text-align: center;
    font-weight: 500;
    color: #777;
    transition: box-shadow 300ms ease-in-out;

    .button-text {
      padding-top: 55px;
    }
  }

  .review-button:hover {
    box-shadow: 5px 5px 15px rgb(126, 151, 173, 0.2);
    transition: box-shadow 300ms ease-in-out;
  }
}

.review-dialog {
  // review-card

  .card {
    cursor: pointer;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    box-shadow: none;
    width: 100%;
    height: 220px;

    transition: all 600ms ease;
    position: relative;
    white-space: initial;
  }
  .card-header,
  .card-link {
    position: relative;
    z-index: 2;
    color: white;
    font-weight: 500;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: rgb(65, 65, 65);
  }

  .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: 600ms;
    background: -webkit-linear-gradient(#252f3d, #252f3d11);
    background: -moz-linear-gradient(#252f3d, #252f3d11);
    background: -o-linear-gradient(#252f3d, #252f3d11);
    background: linear-gradient(#252f3d, #252f3d11);
  }

  .card:hover {
    box-shadow: 5px 5px 15px rgba(160, 169, 177, 1);
  }
  .card:hover .card-link {
    color: #5bff89;
    box-shadow: 5px 5px 15px rgba(160, 169, 177, 1);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .card:hover .overlay {
    opacity: 1;
  }
}

.review-upload {
  .el-dialog__body {
    padding: 0px !important;
  }
  .el-upload--picture-card {
    width: 100%;
    line-height: 1.7;
    height: 95px;
    .el-upload-dragger {
      width: 100%;
      line-height: 0;
      height: 95px;
    }
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 100%;
    height: 250px;
  }
  .el-upload-list__item.is-ready {
    margin: 3px;
  }
  .el-upload-list__item-thumbnail {
    object-fit: cover;
  }
}
</style>
