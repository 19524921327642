<template>
  <div>
    <nav class="navbar is-transparent is-fixed-top">
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        background-color="#f3f3f5"
        :router="true"
      >
        <el-menu-item class="navbar-item" index="1" route="/">
          <router-link to="/">
            <img src="/logo-text.svg" height="10" />
          </router-link>
        </el-menu-item>

        <!-- <el-menu-item index="1" route="/">
          Home
        </el-menu-item> -->
        <!-- <el-submenu index="2">
          <template class="has-text-black" slot="title">More</template>
          <el-menu-item index="2-1">
            <a
              class="has-text-black	"
              href="https://www.facebook.com/CondoRail-110840651089940"
              target="_blank"
            >
              Contact
            </a>
          </el-menu-item>
        </el-submenu> -->

        <div v-if="getLoginStatus" id="signInProfile" class="navbar-item">
          <!-- <el-button
            class="fullscreen-button mr-3"
            icon="el-icon-full-screen"
            @click="toggleFullscreen"
          ></el-button> -->

          <el-dropdown>
            <span class="el-dropdown-link">
              <figure v-if="getUser != ''" class="image is-38x38">
                <img
                  :src="getUser.user.photoURL"
                  class="img-frame is-rounded"
                  alt="Profile image"
                />
              </figure>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>Profile</el-dropdown-item>
              <el-dropdown-item @click.native="logout" divided>
                Sign Out
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div
          v-else-if="this.$route.name != 'Login'"
          id="signInProfile"
          class="navbar-item"
          index="2"
          route="/"
        >
          <!-- <el-button
            class="fullscreen-button mr-3"
            icon="el-icon-full-screen"
            @click="toggleFullscreen"
          ></el-button> -->
          <a
            class="button has-text-white is-small is-primary"
            @click="signUserInGoogle"
          >
            <strong>Sign in</strong>
          </a>
        </div>
      </el-menu>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      showNav: false,
      activeIndex: "0",
    };
  },
  methods: {
    ...mapActions(["signUserInGoogle", "toggleFullscreen"]),
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus", "fullscreen"]),
    console: () => console,
  },
};
</script>

<style scoped>
.navbar {
  background: #f3f3f5;
  box-shadow: 0 0.5em 1em -0.125em #aeaec044;
  display: block;
  align-items: center;
}

.navbar-dropdown.has-shadow {
  box-shadow: 0 0 0.8em 0.3em #aeaec044;
}

.navbar-divider {
  background-color: #e5e5e9;
}

.navbar-dropdown {
  /* width: 8vw; */
  border-top: none;
}

.navbar-item figure img {
  max-height: 3rem;
}

/* .el-menu > .el-menu-item {
  height: 60px !important;
  line-height: 60px !important;
} */

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 3px solid #00ab6c;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-submenu__title:hover,
.el-menu-item:hover {
  background-color: #f3f3f5 !important;
}

#signInProfile {
  float: right;
  display: flex;
  align-items: center;
  height: 60px;
  line-height: 60px;
}

.el-dropdown-link {
  cursor: pointer;
}

.img-frame {
  border: 3px solid rgb(34, 196, 136);
}
</style>
