const state = {
  screenType: "",
  screenWidth: "",

  // fullscreen: false,
  postReviewDialogVisible: false,
  postVideoDialogVisible: false,

  galleryDialogVisible: false,
  reviewsDialogVisible: false,
  videosDialogVisible: false,
};

const getters = {
  screenType: (state) => state.screenType,
  screenWidth: (state) => state.screenWidth,

  // fullscreen: (state) => state.fullscreen,
  postReviewDialogVisible: (state) => state.postReviewDialogVisible,
  postVideoDialogVisible: (state) => state.postVideoDialogVisible,
  galleryDialogVisible: (state) => state.galleryDialogVisible,
  reviewsDialogVisible: (state) => state.reviewsDialogVisible,
  videosDialogVisible: (state) => state.videosDialogVisible,
};

const actions = {
  updateScreenType({ commit }, type) {
    commit("updateScreenType", type);
  },
  updateScreenWidth({ commit }, width) {
    commit("updateScreenWidth", width);
  },

  // toggleFullscreen({ commit }) {
  //   commit("toggleFullscreen");
  // },
  updateDialogVisible({ commit }, { type, isVisible }) {
    commit("updateDialogVisible", { type, isVisible });
  },
};

const mutations = {
  updateScreenType: (state, type) => {
    state.screenType = type;
  },
  updateScreenWidth: (state, width) => {
    state.screenWidth = width;
    // console.log(state.screenWidth);
  },

  // toggleFullscreen: (state) => {
  //   state.fullscreen = !state.fullscreen;
  // },
  updateDialogVisible: (state, { type, isVisible }) => {
    if (type == "postReview") {
      state.postReviewDialogVisible = isVisible;
    } else if (type == "postVideo") {
      state.postVideoDialogVisible = isVisible;
    } else if (type == "gallery") {
      state.galleryDialogVisible = isVisible;
    } else if (type == "review") {
      state.reviewsDialogVisible = isVisible;
    } else if (type == "video") {
      state.videosDialogVisible = isVisible;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
