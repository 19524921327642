import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAnalytics from "vue-analytics";
import VueMeta from "vue-meta";
// import VueGtag from "vue-gtag";

import VueCurrencyInput from "vue-currency-input";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "../css/element-variables.scss";

import VueLazyload from "vue-lazyload";
import VueTimeago from "vue-timeago";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import { auth } from "./firebase";
import { rtdbPlugin } from "vuefire";

// import fullscreen from "vue-fullscreen";

Vue.use(rtdbPlugin);
Vue.use(ElementUI, { locale });
Vue.use(CoolLightBox);
// Vue.use(fullscreen);

const pluginOptions = {
  globalOptions: { currency: null, precision: 0 },
};
Vue.use(VueCurrencyInput, pluginOptions);

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: "UA-193286623-1",
  router,
});

// Vue.use(VueGtag, {
//   config: { id: "G-HG2JB79NDV" }
// }, router);

Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VueLazyload, {
  preLoad: 1.3,
  // loading: "/loading-bg.jpg",
  loading: "/loading.gif",
  error: "/error.jpg",
  attempt: 2,
  throttleWait: 500,
});

let app = "";

auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
