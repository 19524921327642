export default [
  {
    path: "*",
    redirect: "/",
  },
  {
    name: "Home",
    path: "/",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "Station",
    path: "/stations/:lineId/:stationId",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "Condo",
    path: "/condos/:id/:name",
    component: () => import(/* webpackChunkName: "Condo" */ "@/views/Condo"),
    meta: {
      requiresAuth: false,
    },
    children: [],
  },
  {
    name: "Market",
    path: "/condos/:id/:name/marketplace",
    component: () => import(/* webpackChunkName: "Condo" */ "@/views/Market"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "Login",
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/auth/Login"),
  },
];
