import firebase from "firebase/app";
// import { firestore } from "@/firebase";
import router from "@/router";
import { setUser, destroyUser } from "@/services/jwt.service";

export default {
  signUserInGoogle({ commit }) {
    commit("setLoading", true);
    commit("clearErrors");
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((user) => {
        commit("setLoading", false);
        // console.log(user.additionalUserInfo.profile);
        setUser(user);

        var data = user.additionalUserInfo.profile;

        firebase
          .database()
          .ref("users")
          .child(user.user.uid)
          .update(data);

        firebase
          .database()
          .ref("users_profile")
          .child(user.user.uid)
          .update({
            user_id: user.user.uid,
            id: data.id,
            given_name: data.given_name,
            family_name: data.family_name,
            name: data.name,
            picture: data.picture,
            email: data.email,
          });

        // firestore
        //   .collection("users")
        //   .doc(user.user.uid)
        //   .set({
        //     user_id: user.user.uid,
        //     given_name: data.given_name,
        //     family_name: data.family_name,
        //     name: data.name,
        //     picture: data.picture,
        //     email: data.email,
        //   })
        //   .then(() => {
        //     console.log("Document successfully written!");
        //   })
        //   .catch((error) => {
        //     console.error("Error writing document: ", error);
        //   });

        commit("setUser", user);
        // router.push("/").catch(() => {});
        router.go().catch(() => {});
      })
      .catch((error) => {
        commit("setLoading", false);
        commit("setErrors", error);
        // console.log(error);
      });
  },
  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => router.push("/login").catch(() => {}));

    destroyUser();
  },
};
