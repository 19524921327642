import Vuex from "vuex";
import Vue from "vue";
import stations from "./modules/stations";
import condo from "./modules/condo";
import user from "./modules/user";
import view from "./modules/view";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    user,
    stations,
    condo,
    view,
  },
});
