function shuffleObject(obj) {
  //   console.log(obj);
  // new obj to return
  let newObj = {};
  // create keys array
  var keys = Object.keys(obj);
  // randomize keys array
  keys.sort(function() {
    return Math.random() - 0.5;
  });
  // save in new array
  keys.forEach(function(k) {
    newObj[k] = obj[k];
  });
  return newObj;
}

export default { shuffleObject };
