import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
// import "firebase/firestore";

// const env = process.env;
// const firebaseConfig = {
//   apiKey: env.VUE_APP_API_KEY,
//   authDomain: env.VUE_APP_AUTH_DOMAIN,
//   databaseURL: env.VUE_APP_DATABASE_URL,
//   projectId: env.VUE_APP_PROJECT_ID,
//   storageBucket: env.VUE_APP_STORAGE_BUCKET,
//   messagingSenderId: env.VUE_APP_MESSAGING_SENDER_ID,
//   appId: env.VUE_APP_APP_ID,
//   measurementId: env.VUE_APP_MEASUREMENT_ID
// };

const firebaseConfig = {
  apiKey: "AIzaSyCvrcBv6scV9KmTexiavm71rnrEVKPOJZg",
  authDomain: "condomap-pcrete.firebaseapp.com",
  databaseURL: "https://condomap-pcrete-default-rtdb.firebaseio.com",
  projectId: "condomap-pcrete",
  storageBucket: "condomap-pcrete.appspot.com",
  messagingSenderId: "540752065616",
  appId: "1:540752065616:web:c1f458b8ffb3ef1e0d8d55",
  measurementId: "G-RNY3RQ7LTE",
};

firebase.initializeApp(firebaseConfig);

// utils

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();
// const firestore = firebase.firestore();

// export utils/refs
export { db, auth, storage };
