import { db } from "@/firebase";
import helpers from "@/utils/helpers";
const state = {
  activeLine: "",
  activeStation: "",
  activeCondo: "",
  condo: "",
  lines: {},
  stations: {},
  station: {},
  condos: {},
  condosHighlight: {},
  gmapsKey: "AIzaSyDlGfgVWDaJLh6BZ6GiZms6Upud-WzT27Q",
};

const getters = {
  activeLine: (state) => state.activeLine,
  activeStation: (state) => state.activeStation,
  activeCondo: (state) => state.activeCondo,
  lines: (state) => state.lines,
  stations: (state) => state.stations,
  station: (state) => state.station,
  condo: (state) => state.condo,
  condos: (state) => state.condos,
  gmapsKey: (state) => state.gmapsKey,
};

const actions = {
  updateLineStation({ commit }, { lineId, stationId }) {
    commit("updateLineStation", { lineId, stationId });
  },
  updateCondo({ commit }, condoId) {
    commit("updateCondo", condoId);
  },
  fetchLines({ state, commit }) {
    if (Object.keys(state.lines).length == 0) {
      db.ref("lines").once("value", (snapshot) => {
        commit("fetchLines", snapshot.val());
      });
    }
  },
  fetchStations({ state, commit }) {
    if (Object.keys(state.stations).length == 0) {
      db.ref("stations").once("value", (snapshot) => {
        commit("fetchStations", snapshot.val());
      });
    }
  },
  fetchStation({ commit }, stationId) {
    commit("fetchStation", stationId);
  },
  fetchCondo({ commit }, condoId) {
    db.ref("condos")
      .child(condoId)
      .on("value", (snapshot) => {
        commit("fetchCondo", { condo: snapshot.val(), condoId: condoId });
      });
  },
  fetchCondos({ state, commit }, stationId) {
    if (state.activeStation == "") {
      // commit("fetchCondos", state.condosHighlight);
    } else {
      // Find condos within 1km of the station
      const radiusInM = 1 * 1100;

      db.ref("condos")
        .orderByChild("station_distances/" + stationId)
        .startAt(0)
        .endAt(radiusInM)
        .once("value", (snapshot) => {
          const matchingDocs = {};
          snapshot.forEach((childSnapshot) => {
            var document = childSnapshot.val();
            if (!document.hidden) {
              var score = document.rating * 10 * document.user_ratings_total;
              if (score > 400) {
                matchingDocs[document.place_id] = document;
              }
            }
          });
          commit("fetchCondos", matchingDocs);
        });
    }
  },
  fetchCondosHighlight({ state, commit }) {
    if (
      state.activeStation == "" &&
      Object.keys(state.condosHighlight).length == 0
    ) {
      db.ref("condos")
        .orderByChild("highlight")
        .equalTo(true)
        .once("value", (snapshot) => {
          const matchingDocs = {};
          snapshot.forEach((childSnapshot) => {
            var document = childSnapshot.val();
            if (!document.hidden) {
              matchingDocs[document.place_id] = document;
            }
          });
          commit("fetchCondosHighlight", helpers.shuffleObject(matchingDocs));
        });
    }
  },
};

const mutations = {
  fetchLines(state, lines) {
    state.lines = lines;
  },
  fetchStations(state, stations) {
    state.stations = stations;
  },
  fetchStation(state, stationId) {
    state.station = state.stations[stationId];
  },
  fetchCondo: (state, { condo, condoId }) => {
    state.condo = condo;
    state.activeCondo = condoId;
  },
  updateCondo: (state, condoId) => {
    if (state.activeCondo == condoId) {
      state.activeCondo = "";
      state.condo = "";
    } else {
      state.activeCondo = condoId;
      state.condo = state.condos[condoId];
    }
  },
  updateLineStation: (state, { lineId, stationId }) => {
    if (state.activeStation == stationId) {
      state.activeLine = "";
      state.activeStation = "";
    } else if (lineId == "" && stationId == "") {
      state.activeLine = "";
      state.activeStation = "";
    } else {
      if (lineId == "") {
        if (stationId.includes("A")) state.activeLine = "ARL";
        else if (stationId.includes("BL")) state.activeLine = "BL";
        else if (stationId.includes("PL")) state.activeLine = "PPL";
        else if (stationId.includes("PL")) state.activeLine = "PPL";
        else if (stationId.includes("S") || stationId.includes("W"))
          state.activeLine = "GL";
        else state.activeLine = "LGL";
      } else {
        state.activeLine = lineId;
      }
      state.activeStation = stationId;
      // console.log(lineId, stationId);
    }
  },
  fetchCondos: (state, newCondos) => {
    state.condos = newCondos;
  },
  fetchCondosHighlight: (state, newCondos) => {
    state.condos = newCondos;
    // state.condosHighlight = newCondos;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
